import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';

class App extends Component {
  render() {
    return (
      
          <div className="container">
            
            <div className="page-header">
              <h2><span className="glyphicon glyphicon-info-sign" aria-hidden="true"></span> Otevírací doba</h2>
            </div>
            
            <p className="lead">
              <ul>
                <li><span className="badge">Po - Čt</span>  <strong><span>9:00 - 12:00, 13:00 - 17:00</span></strong></li>
                <li><span className="badge">Pá, So, Ne</span>  <strong><span style={{ color: "red" }}>zavřeno</span></strong></li>
              </ul>
            </p>

            <div className="page-header">
              <h2><span className="glyphicon glyphicon-phone-alt" aria-hidden="true"></span> Kontakt</h2>
            </div>

            <p className="lead">
              <ul>
                <li><span className="badge">Mob</span>  <a href="tel:+420736534140">+420 736 534 140</a></li>
                <li><span className="badge">Mob</span>  <a href="tel:+420736534239">+420 736 534 239</a></li>
                <li><span className="badge">Email</span> <span><a href="mailto:zlatnictvi@tobolovi.com">zlatnictvi@tobolovi.com</a></span></li>
              </ul>
            </p>

            <div className="page-header">
              <h2><span className="glyphicon glyphicon-map-marker" aria-hidden="true"></span> Kde nás najdete</h2>
            </div>

            <div className="google-maps">
              <iframe src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2555.2489017820244!2d15.737345915856707!3d50.17518241504163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470c2c6f3996c921%3A0x194e6e39f7ce4a02!2sPraska%C4%8Dka+82%2C+503+33+Praska%C4%8Dka!5e0!3m2!1scs!2scz!4v1547390433478`} width="600" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen></iframe>
            </div>

          </div>

    
    );
  }
}

export default App;
